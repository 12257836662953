import { lazy } from 'react';

// project imports
//import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/index')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const PostJobs = Loadable(lazy(() => import('views/post-job')));

const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));

const MyWOrk = Loadable(lazy(() => import('views/myWork')));

const AcceptedTask = Loadable(lazy(() => import('views/myWork/acceptedTask')));
const MyJobs = Loadable(lazy(() => import('views/myWork/myJobs')));

const Notifications = Loadable(lazy(() => import('views/pages/notifications')));
const InstantDeposit = Loadable(lazy(() => import('views/deposit/inistantDeposit')));

const ManualDeposit = Loadable(lazy(() => import('views/deposit/manualDeposit')));
const Withdraw = Loadable(lazy(() => import('views/transactions/withdraw')));

const Deposit = Loadable(lazy(() => import('views/transactions/deposit')));

const Ads = Loadable(lazy(() => import('views/ads')));
const Tricket = Loadable(lazy(() => import('views/tricket')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/job',
            element: <Dashboard />
        },
        {
            path: '/post-job',
            element: <PostJobs />
        },
        {
            path: '/task',
            element: <MyWOrk />
        },
        {
            path: '/task/accepted',
            element: <AcceptedTask />
        },
        {
            path: '/my-job',
            element: <MyJobs />
        },
        {
            path: '/notifications',
            element: <Notifications />
        },
        {
            path: '/instant-deposit',
            element: <InstantDeposit />
        },
        {
            path: '/manual-deposit',
            element: <ManualDeposit />
        },
        {
            path: '/withdraw',
            element: <Withdraw />
        },
        {
            path: '/deposit_history',
            element: <Deposit />
        },
        {
            path: '/ads',
            element: <Ads />
        },
        {
            path: '/ticket',
            element: <Tricket />
        }
    ]
};

export default MainRoutes;
